<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="postData"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="toExport()"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">年份：</div>
          <v-date
            v-model="belongYear1"
            type="year"
            placeholder="选择年份"
            format="yyyy"
          >
          </v-date>
          <div style="font-size: 14px; margin-right: 10px; margin-left: 10px">
            月份：
          </div>
          <v-date-picker
            format="MM"
            v-model="datetimerange"
            type="monthrange"
            clearable
            @change="changeDate"
          ></v-date-picker>
        </div>
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "taxSourcePage",
  data() {
    return {
      headers: [
        {
          prop: "taxBelonging",
          label: "税收归属",
        },
        {
          prop: "taxpayerRegistrationNumber",
          label: "纳税人代码",
        },
        {
          prop: "taxType",
          label: "税种",
        },
        {
          prop: "revenue",
          label: "税收",
        },
        {
          prop: "checkTotal",
          label: "核准总计",
        },
        {
          prop: "companyName",
          label: "纳税人名称",
        },
        {
          prop: "belongYear",
          label: "年份",
        },
        {
          prop: "belongMonth",
          label: "月份",
        },
      ],
      datetimerange: [],
      tableUrl: getBusinessRevenueUrl,
      postData: {
        startMonth: null,
        endMonth: null,
        belongYear: null,
        taxpayerRegistrationNumber: null,
      },
      belongYear1: null,
    };
  },
  watch: {
    belongYear1: {
      handler(nVal, oVal) {
        if (nVal) {
          this.postData.belongYear = nVal.getFullYear();
        } else {
          this.postData.belongYear = nVal;
        }
      },
    },
  },
  created() {
    this.postData.taxpayerRegistrationNumber =
      this.$route.query.taxpayerRegistrationNumber;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    changeDate() {
      if (this.datetimerange && this.datetimerange.length == 2) {
        this.postData.startMonth = this.datetimerange[0].format("MM");
        this.postData.endMonth = this.datetimerange[1].format("MM");
      } else {
        this.postData.startMonth = null;
        this.postData.endMonth = null;
      }
    },
    // 删除
    remove(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${deleteBusinessRevenueUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    // 导出
    toExport() {
      let params = {
        taxpayerRegistrationNumber:
          this.$route.query.taxpayerRegistrationNumber,
        belongYear: this.postData.belongYear || "",
        startMonth: this.postData.startMonth || "",
        endMonth: this.postData.endMonth || "",
      };
      this.$axios
        .get(
          `/gateway/hc-arch/buildingRegulate/exportBusinessRevenue?taxpayerRegistrationNumber=${params.taxpayerRegistrationNumber}&belongYear=${params.belongYear}&startMonth=${params.startMonth}&endMonth=${params.endMonth}`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  height: 100%;
  box-sizing: border-box;
}
</style>
